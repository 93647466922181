import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Card, Title, Button, Share,
} from '@lxlabs/change-ad-components';

const Style = styled(Card)`
  margin: 0px !important;
`;

const DownloadCard = ({
  title, url, shareOptions, backgroundColor,
}) => (
  <Style backgroundColor={backgroundColor}>
    <Title size="small" alignment="center">
      {title}
    </Title>
    <Button
      icon="download"
      spacing={{ bottom: shareOptions && 'large', top: 'large' }}
      text="Download"
      href={url}
      download
    />
    {shareOptions ? <Share name={title} {...shareOptions} /> : ''}

    {/* If there isn't any share options, add in a fake div which would mean it would have the
     * auto margin applied to it.
     */}
    {!shareOptions && <div />}
  </Style>
);

DownloadCard.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  shareOptions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    text: PropTypes.object,
  }).isRequired,
  backgroundColor: PropTypes.string.isRequired,
};

export default DownloadCard;
